<template>
    <div>
        <div v-if="showDateInput">
            <flat-pickr
                v-model="currentTodo.deadline"
                class="form-control"
                :config="flatpickrConfig"
                @on-change="updateDeadline"
            ></flat-pickr>
        </div>

        <div v-if="loading">
            <p class="renew">Actiepunt ophalen...</p>
        </div>

        <div v-else-if="currentTodo">
            <table class="table table-striped">
                <thead class="">
                    <tr>
                        <th><i class="fa fa-chevron-left" @click="goBack"></i></th>
                        <th>{{ todoType }} <i v-if="currentTodo.priority !== 0" class="fa fa-star white"></i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="currentTodo.finished === 1">
                        <td><i class="fa fa-flag-checkered"></i></td>
                        <td>{{ currentTodo.date_finished }}</td>
                    </tr>

                    <tr v-else>
                        <td><i class="fa fa-clock-o"></i></td>
                        <td>{{ currentTodo.viewDate }}</td>
                    </tr>

                    <tr>
                        <td><i class="fa fa-id-badge"></i></td>
                        <td>{{ currentTodo.customer.name }}</td>
                    </tr>

                    <tr v-if="currentTodo.project">
                        <td><i class="fa fa-building-o"></i></td>
                        <td>{{ currentTodo.project.name }}</td>
                    </tr>

                    <tr>
                        <td><i class="fa fa-tasks"></i></td>
                        <td>{{ currentTodo.description }}</td>
                    </tr>

                    <tr v-if="currentTodo.notes != ''">
                        <td><i class="fa fa-info-circle" :title="currentTodo.notes" v-tippy></i> </td>
                        <td>Notes</td>
                    </tr>
                </tbody>
            </table>

            <div id="action-buttons">
                <button v-if="currentTodo.finished === 0" class="btn btn-primary" type="button" @click="finish"><i class="fa fa-flag-checkered"></i></button>
                <button v-if="currentTodo.finished === 0" class="btn btn-primary" type="button" @click="deleteTodoFromButton"><i class="fa fa-trash"></i></button>
                <button v-if="currentTodo.finished === 0" class="btn btn-primary" type="button" @click="showDatepicker"><i class="fa fa-clock-o"></i></button>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/light.css'
import { Dutch } from 'flatpickr/dist/l10n/nl.js'
import dayjs from 'dayjs'
import 'dayjs/locale/nl'
dayjs.locale('nl') // use loaded locale globally
import errorHandler from '@/libraries/error-handler'
import { Notyf } from 'notyf'; // For error notifications
import 'notyf/notyf.min.css';

export default {
    name: 'Todo',
    components: {flatPickr},
    data() {
        return {
            loading: true,
            currentTodo: null,
            showDateInput: false,
            flatpickrConfig: {
                inline: true,
                locale: Dutch,
            },
            currentDate: dayjs(this.$route.params.date, 'YYYYMMDD'),
            notyf: new Notyf({duration: 3000}),
        }
    },
    computed: {
        ...mapState('todos', ['digest']),
        myTodayString() {
            return this.currentDate.format('YYYY-MM-DD')
        },
        todoType() {
            switch(this.$route.params.type) {
                case 'todoitem':
                    return 'Actiepunt'
                    break
                case 'trello-todoitem':
                    return 'Trello actiepunt'
                    break
                case 'recurring-todoitem':
                    return 'Terugkerend actiepunt'
                    break
                default:
                    return 'Actiepunt'
            }
        },
    },
    methods: {
        ...mapActions('todos', ['finishTodo', 'updateTodoDeadline', 'convertToSingle', 'getTodo', 'deleteSingleDay', 'deleteTodo']),
        getTodoItem() {
            // Try to find the todo in the current digest list. We only check
            // for today's date. If this is an todo for another date, we just
            // retrieve it from the API
            const todoId = parseInt(this.$route.params.id)
            const todo = this.digest[this.myTodayString].find(item => item.id === todoId)
            if (todo) {
                this.setCurrentTodo(todo)
                this.loading = false
            } else {
                this.getTodo(this.$route.params)
                .then(this.setCurrentTodo)
                .catch(err => {
                    const {formErrors: issues} = errorHandler(err)
                    this.handleError(`Ophalen van actiepunt lukte niet: ${issues.join(',')}`)
                })
                .finally(() => this.loading = false)
            } 
        },
        setCurrentTodo(todo) {
            this.currentTodo = {
                ...todo,
                viewDate: dayjs(todo.deadline ?? this.currentDate).format('dddd D MMMM')
            }
        },
        goBack() {
            if (window.history.length === 1) {
                // first visit, return to overview page or home
                this.$router.go('/')
            } else {
                this.$router.go(-1)
            }
        },
        finish() {
            this.finishTodo({
                id: this.$route.params.id,
                type: this.$route.params.type,
                dateString: this.myTodayString
            })
            .then(() => {
                this.notyf.success('Goed bezig!')
                this.goBack()
            })
        },
        deleteTodoFromButton() {
            const type = this.currentTodo.baseroute
            let deletePromise
            const postData = {
                id: this.currentTodo.id, 
                type, 
                date: this.myTodayString
            }
            if (type !== 'recurring-todoitem') {
                deletePromise = this.deleteTodo(postData)
            } else {
                deletePromise = this.deleteSingleDay(postData)
            }
            deletePromise
            .then(() => {
                this.notyf.success('En... weg is-ie!')
                this.goBack()
            })
            .catch(() => this.notyf.error('Verwijderen actiepunt niet gelukt'))
        },
        showDatepicker() {
            this.showDateInput = true
        },
        updateDeadline(newDate, newDateStr) {
            this.currentTodo.deadline = newDateStr
            this.currentTodo.viewDate = dayjs(newDateStr).format('dddd D MMMM')

            // close
            this.showDateInput = false

            // update via API
            const {id, type} = this.$route.params
            let updateDeadlinePromise
            if (type === 'recurring-todoitem') {
                updateDeadlinePromise = this.convertToSingle({
                    currentDate: this.myTodayString,
                    newDate: newDateStr,
                    id
                })
            } else {
                updateDeadlinePromise = this.updateTodoDeadline({...this.$route.params, deadline: newDateStr})
            }
            
            updateDeadlinePromise
            .then(response => {
                this.notyf.success('Deadline is verplaatst')
                this.goBack()
            })
            .catch(err => {
                this.notyf.error('Het aanpassen van de deadline is niet gelukt')
            })
        },
        handleError(errorMessage) {
            this.notyf.error(errorMessage)
        },
    },
    mounted() {
        this.getTodoItem()
    },
};
</script>

<style scoped lang="less">
table {
    thead th, tbody td {
        width: 10%;
    }

    thead th:last-child, tbody td:last-child {
        width: 90%;
    }
}

#action-buttons {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
}
</style>